import {
  ActionCodeSettings,
  Auth,
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithCustomToken,
  signInWithEmailLink as signInWithEmailLinkFirebase,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';

import { FirebaseApp } from './init';

import analytics from '@/segment';
import getCurrentEnv from '@/utils/getCurrentEnv';

export const FirebaseAuth = getAuth(FirebaseApp);

const AppleProvider = new OAuthProvider('apple.com');

const isLocalEnv = getCurrentEnv() === 'development';
if (isLocalEnv) {
  connectAuthEmulator(
    FirebaseAuth,
    'http://localhost:9099',
    { disableWarnings: true }, // Comment to show emulator mode warning banners on screens
  );
}

/** Getters */

export const getCurrentUser = async () => {
  const promisifiedOnAuthStateChanged = (FirebaseAuth: Auth) => {
    return new Promise<User | null>(resolve => {
      FirebaseAuth.onAuthStateChanged((user: User | null) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      });
    });
  };
  return await promisifiedOnAuthStateChanged(FirebaseAuth);
};

export const getFirebaseAuthToken = async () => {
  try {
    const appToken = sessionStorage.getItem('token');

    if (appToken) {
      return await signInWithAppToken(appToken)
        .then(getCurrentUser)
        .then(user => user?.getIdToken());
    } else {
      return await getCurrentUser().then(user => user?.getIdToken());
    }
  } catch (error) {
    analytics.track('web_auth_error', { error });
  }
};

/** Sign in methods */

export const signInWithAppToken = async (token: string) => {
  try {
    await signInWithCustomToken(FirebaseAuth, token).then(res => {
      if (res?.user?.email) {
        analytics.identify({ email: res.user.email });
      }
      return res?.user?.displayName;
    });
  } catch (error) {
    analytics.track('web_auth_error', { error });
  }
};

export const signInWithGoogle = async (callback?: () => void) => {
  try {
    signInWithPopup(FirebaseAuth, new GoogleAuthProvider())
      .then(res => {
        if (res?.user?.email) {
          analytics.identify({ email: res.user.email });
        }

        return res?.user?.displayName;
      })
      .then(callback);
  } catch (error) {
    analytics.track('web_auth_error', { error });
  }
};

export const signInWithApple = async (callback?: () => void) => {
  signInWithPopup(FirebaseAuth, AppleProvider)
    .then(res => {
      if (res?.user?.email) {
        analytics.identify({ email: res.user.email });
      }

      return res?.user?.displayName;
    })
    .then(callback)
    .catch(error => {
      analytics.track('web_auth_error', { error });
    });
};

export const signInWithEmailLink = async (
  email: string,
  redirectURL: string,
  searchParams: string,
) => {
  try {
    const actionCodeSettings: ActionCodeSettings = {
      url: `${window.location.origin}${redirectURL}${searchParams}&email=${encodeURIComponent(email)}`,
      handleCodeInApp: true,
    };

    analytics.identify({ email });

    await sendSignInLinkToEmail(FirebaseAuth, email, actionCodeSettings);
  } catch (error) {
    analytics.track('web_auth_error', { error });
  }
};

export const confirmSignIn = async (
  url: string,
  email: string | null | undefined,
) => {
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser && email && isSignInWithEmailLink(FirebaseAuth, url)) {
      return await signInWithEmailLinkFirebase(FirebaseAuth, email, url)
        .then(getCurrentUser)
        .then(user => {
          if (user?.email) analytics.identify({ email: user.email });

          return user;
        });
    } else {
      return currentUser;
    }
  } catch (error) {
    analytics.track('web_auth_error', { error });
  }
};

export const signUserOut = async (callback?: () => void) => {
  try {
    signOut(FirebaseAuth)
      .then(() => {
        localStorage.clear();
        analytics.reset();
      })
      .then(callback);
  } catch (error) {
    analytics.track('web_auth_error', { error });
  }
};
